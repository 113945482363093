/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './stylesheets/application.scss'
import './splide/splide.js'

// Mobile  menu

  const displayMobileMenu = () => {
    const navToggle = document.getElementById('navToggle');
    const navMenu = document.getElementById('navMenu');
    navToggle.addEventListener("click", () => {
      navToggle.classList.toggle("active");
      navMenu.classList.toggle("active");
    })
    document.querySelectorAll(".nav-menu__link").forEach( n => n.addEventListener("click", () => {
      navToggle.classList.remove("active");
      navMenu.classList.remove("active");
    }))
}

// Tabs

  const setupTabs = () => {
    document.querySelectorAll(".tabs__button").forEach(button => {
      button.addEventListener("click" , () => {
        const tabsItem = button.parentElement;
        const tabs = tabsItem.parentElement;
        const tabsContainer = tabs.parentElement;
        const tabNumber = button.dataset.forTab;
        const tabToActivate = tabsContainer.querySelector(`.tab-pane[data-tab="${tabNumber}"]`);


        tabsContainer.querySelectorAll(".tabs__button").forEach(button => {
          button.classList.remove("active");
        });
        tabsContainer.querySelectorAll(".tab-pane").forEach(tab => {
          tab.classList.remove("active");
        });
        button.classList.add("active");
        tabToActivate.classList.add("active");

      })
    })
  }

document.addEventListener("DOMContentLoaded", () => {
  displayMobileMenu();
  setupTabs();

  document.querySelectorAll(".tabs__container").forEach(tabsContainer => {
    tabsContainer.querySelector(".tabs .tabs__button").click();
  })

})
