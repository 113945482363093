import Splide from '@splidejs/splide';

document.addEventListener( 'DOMContentLoaded', function() {
  var splide = new Splide( '.splide', {
    arrows: false,
    gap: '1rem',
    perMove: 1,
    padding: '2rem',
    start: 1,
    //autoWidth: true,  uncomment when pricing plan is ready
    focus  : 'center',
    perPage: 1,
    mediaQuery: 'min',
    /* breakpoints: {  uncomment when pricing plan is ready
      1400: {
        destroy: true,
      },
    } */
  } );
  splide.mount();
} );
